// - - - - - - - - - - - - - - - - - -

// Import from Source modal

// - - - - - - - - - - - - - - - - - -

@import "../_base/_mixins";

.from-source-modal {
	padding: 40px 40px 0;



	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Logo

	&__logo {

		svg {
			display: block;
			margin: 0 auto;
			height: 64px;
		}
	}



	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - First run

	&__first-run {


		&__title {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;

			span {
				font-size: 25px;
				font-weight: 600;
			}

			svg {
				height: 40px;
			}
		}

		&__image {
			margin: 20px auto 0;
			max-width: 458px;

			img {
				display: block;
			}
		}

		&__text {
			margin: 10px auto 0;
			max-width: 540px;
			text-align: center;

			p {
				font-size: 16px;
				line-height: 1.4;
			}

			.button {
				margin-top: 20px;
			}
		}		
	}



	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Slate choice

	&__intro {
		margin-top: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-flow: wrap;

		h1 {
			font-size: 25px;
			line-height: 1.2;
			padding-right: 10px;
			text-align: center;
		}

		input {
			width: auto;
		}

		p {
			width: 100%;
			text-align: center;
			font-size: 12px;
			color: var(--text-medium-color);
		}

		&--success h1 {
			color: var(--accent-color);
		}
	}

	.slate-selector {
		position: relative;
		z-index: 10;

		&__selected {
			position: relative;
			z-index: 1;
			display: flex;
			align-items: center;
			justify-content: space-between;
			min-width: 300px;
			padding: 4px 9px 4px 0;
			background: var(--background-light-color);
			border-radius: 6px;
			border: 1px solid var(--border-color);
			cursor: pointer;
			transition: border-color 0.1s $easey;
		}

		&__toggle {
			display: flex;
			width: 40px;
			height: 40px;

			svg {
				margin: auto;
				height: 20px;
				fill: var(--text-light-color);
				transition: fill 0.1s $easey;
			}
		}

		&__options {
			position: absolute;
			top: 59px;
			width: 100%;
			left: 0;
			padding: 5px 0;
			background: var(--background-light-color);
			border-radius: 0 0 6px 6px;
			border: 1px solid var(--accent-faded-color);
			box-shadow: 0px 5px 30px unquote("hsla(var(--background-dark-color-hsl),0.06)");

			opacity: 0;
			visibility: hidden;
			transform: translate(0,-8px);
			transition: all 0.2s $easey;
		}

		&__item {
			display: flex;
			align-items: center;
			padding: 5px 10px;
			cursor: pointer;

			&__image {
				width: 40px;
				height: 40px;
				padding: 6px;
				background: var(--text-light-color);
				border-radius: 3px;

				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}

			&__name {
				padding-left: 10px;
				font-size: 20px;
				font-weight: 600;
				transition: color 0.1s $easey;
			}
		}

		&:hover {

			.slate-selector__selected {
				border-color: var(--accent-faded-color);
			}

			.slate-selector__toggle svg {
				fill: var(--accent-color);
			}
		}

		&--selecting {

			.slate-selector__selected {
				border-radius: 6px 6px 0 0;
				border-color: var(--accent-faded-color);

				.slate-selector__item__name {
					color: var(--text-light-color);
				}
			}

			.slate-selector__toggle svg {
				fill: var(--accent-color);
			}

			.slate-selector__options {
				opacity: 1;
				visibility: visible;
				transform: translate(0,0);

				.slate-selector__item:hover {

					.slate-selector__item__name {
						color: var(--accent-color);
					}
				}
			}
		}
	}



	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Spots

	&__spots {
		margin-top: 40px;
		padding-bottom: 170px;
	}

	.clip-card {
		position: relative;
		max-width: 540px;
		margin: 6px auto 0;
		border-radius: 6px;
		background: var(--background-light-color);
		box-shadow: inset 0 0 0 1px var(--border-color);

		&:first-child {
			margin-top: 0;
		}

		&:nth-child(2n) {
			background: var(--background-alt-color);
		}

		&__content {
			display: flex;
			align-items: center;
			padding: 7px 50px 7px 7px;
		}

		&__image {
			position: relative;
			aspect-ratio: 16 / 9;
			height: 66px;
			border-radius: 3px;
			overflow: hidden;
			flex-shrink: 0;

			img {
				position: absolute;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&__loader {
			position: absolute;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			background: var(--text-light-color);

			svg {
				height: 20px;
				fill: var(--background-color);
			}
		}

		&__details {
			padding-left: 10px;

			h2,
			h3 {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				overflow: hidden;
				line-height: 1.15;
			}

			h2 {
				font-size: 16px;
				-webkit-line-clamp: 2;
			}

			h3 {
				margin-top: 3px;
				font-size: 12px;
				font-weight: 400;
				color: var(--text-medium-color);
				-webkit-line-clamp: 1;
			}
		}

		&__remove {
			position: absolute;
			top: 0;
			right: 0;
			width: 33px;
			height: 33px;
			display: flex;
			color: var(--text-light-color);

			opacity: 0;
			visibility: hidden;
			transition: all 0.1s $easey;
			cursor: pointer;

			svg {
				height: 16px;
				margin: auto;
				fill: currentColor;
			}
		}

		&__success {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			border: 1px solid transparent;
			border-radius: 6px;
			transition: border-color 0.2s $easey;

			&:hover {
				border-color: var(--accent-color);
			}

			&__icon {
				position: absolute;
				top: 7px;
				right: 7px;
				width: 20px;
				height: 20px;
				border-radius: 100%;
				border: 2px solid var(--accent-color);
				background: var(--text-inverse-color);
				display: flex;

				svg {
					margin: auto;
					height: 10px;
					fill: var(--accent-color);
				}
			}
		}

		&__exists,
		&__error {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: var(--border-color);
			font-size: 10px;
			padding-bottom: 1px;
			border-radius: 0 0 6px 6px;

			span {
				padding: 7px;
			}

			a {
				padding: 7px;
				height: 100%;
				font-weight: 600;
			}
		}

		&__error {
			background: var(--red-color);
			color: var(--text-inverse-color);
			font-weight: 600;
		}

		&--large {
			max-width: 420px;
			
			.clip-card__content {
				padding: 10px;
				flex-direction: column;
			}

			.clip-card__image {
				width: 100%;
				height: auto;
			}

			.clip-card__loader svg {
				height: 40px;
			}

			.clip-card__details {
				width: 100%;
				padding: 20px 0 10px;
			}

			.clip-card__success__icon {
				top: 14px;
				right: 14px;
			}

			.clip-card__exists {

				span, a {
					padding: 10px;
				}
			}
		}

		&--error {
			box-shadow: inset 0 0 0 1px var(--red-color);
		}

		&:hover {

			.clip-card__remove {
				opacity: 1;
				visibility: visible;

				&:hover {
					color: var(--red-color);
				}
			}
		}
	}



	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Actions

	&__actions {
		position: fixed;
		bottom: 0;
		right: 0;
		left: 0;
		padding: 40px 0;
		text-align: center;

		&:before {
			content: '';
			position: absolute;
			top: 30px;
			right: 0;
			bottom: 0;
			left: 0;
			background: var(--background-color);
		}

		&:after {
			content: '';
			position: absolute;
			top: -30px;
			right: 0;
			left: 0;
			height: 60px;
			background: linear-gradient(unquote("hsla(var(--background-color-hsl),0)"), unquote("hsla(var(--background-color-hsl),1)"));
		}

		.button {
			position: relative;
		}

		&__message {
			position: relative;
			margin-top: 12px;
			font-size: 13px;
			font-weight: 600;
		}
	}

	&__progress {
		position: relative;

		&__bar {
			position: relative;
			width: 300px;
			margin: 0 auto;
			height: 22px;
			border: 1px solid var(--accent-color);
			border-radius: 12px;
			background: var(--text-inverse-color);
			overflow: hidden;

			&__progress {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				background: var(--accent-color);
				width: 0;
				transition: width 2s cubic-bezier(.3,.08,.57,.77);
			}
		}

		&__text {
			margin-top: 15px;
			font-size: 13px;
			font-weight: 600;
			color: var(--accent-color);
		}
	}
}